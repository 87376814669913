//import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import GUI from 'lil-gui'
//import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
//import { createNoise2D } from 'simplex-noise';

// Tools
const lerp = (x, y, a) => x * (1 - a) + y * a;
const clamp = (a, min = 0, max = 1) => Math.min(max, Math.max(min, a));
const invlerp = (x, y, a) => clamp((a - x) / (y - x));
const range = (x1, y1, x2, y2, a) => lerp(x2, y2, invlerp(x1, y1, a));


const gui = new GUI()
gui.close()
gui.hide()

const myObject = {

  myFunction: function () {
    document.querySelector('.webgl').classList.toggle('control')
  },

};
gui.add(myObject, 'myFunction').name('3D Orbit Control'); // Button


function isTouchDevice() {
  return window.ontouchstart !== undefined;
}

var isTouch = isTouchDevice();

/**
 * Raycaster
 */
const raycaster = new THREE.Raycaster()
const objectsToTest = []

//var
var state = 0;

const white = new THREE.Color(0xFFFFFF)
const black = new THREE.Color(0x000000)


var hover_start = .6
var focusMode = false;
var scrollMode = false;
var focusTarget;
var target;
var memo_scroll;
var deltaScroll;
var focusReady;
var angleMain;
var nProject = -1;
var lastProject;
var memoCard = { px: 0, py: 0, px: 0, rx: 0, ry: 0, rz: 0 }
var focusTarget = { px: 0, py: 0, px: 0, rx: 0, ry: 0, rz: 0 }

var msg_end = false
var nohover = false
var touchAs = true
var first_card_closed = false

var videos = []
var htmlVideos;

var music;
var flip;
var sound_ready = false;
var video_ready = false;
var okScroll = true;

var touchStartY;

var speed = isTouch ? .015 : .03

//perlin
//const noise2D = createNoise2D();
const seed = Math.random() * 1000;


//DOM

const roundText = document.querySelector('.text-bottom')
const roundScroll = document.querySelector('.scroll-circle')
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()


/**
 * Sizes
 */
var sizes = {
  width: window.innerWidth,
  height: window.innerHeight
}


var scrollBox = document.querySelector('.scroll-box')


var start_decal = 0
var scrolling = start_decal
var dest_scrolling = start_decal

/**
 * Camera
 */

const pov = isTouch ? 16 : 10;

const camera = new THREE.PerspectiveCamera(pov, sizes.width / sizes.height)
camera.position.z = 9
camera.position.x = 9
camera.position.y = 9

scene.add(camera)

/**
 * Renderer
 */

const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  alpha: false,
  antialias: true
})

renderer.outputColorSpace = THREE.LinearSRGBColorSpace
renderer.setPixelRatio(window.devicePixelRatio);


var fogColor = 0x5A5A5A
renderer.setClearColor(fogColor);
scene.fog = new THREE.Fog(fogColor, 16, 19);
scene.background = new THREE.Color(fogColor);

renderer.toneMapping = THREE.LinearToneMapping
renderer.toneMappingExposure = isTouch ? 1.15 : 1.05

window.addEventListener('resize', onWindowResize);
onWindowResize();

function onWindowResize() {

  sizes = {
    width: window.innerWidth,
    height: window.innerHeight
  }

  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();
  renderer.setSize(sizes.width, sizes.height);
}

/**
 * Orbit controls
 */

const controls = new OrbitControls(camera, renderer.domElement);
controls.enableDamping = true;

controls.enableDamping = true;
controls.dampingFactor = .01;
controls.zoomSpeed = .7;
controls.minDistance = 0;

/**
 * Loading manager
 */

const loadingManager = new THREE.LoadingManager(
  // Loaded
  () => {
    renderer.render(scene, camera);
    requestAnimationFrame(animate);
    //document.querySelector('.bottom-preload').classList.remove('show')

    document.querySelector('.text_loading').classList.add('hide')
    document.querySelector('.losange-preload').classList.add('hide')

    document.querySelector('.text_m').classList.remove('hide')
    document.querySelector('.preload_m').classList.remove('hide')


    setTimeout(() => {
      document.querySelector('.text_m').classList.add('hide')
      document.querySelector('.preload_m').classList.add('hide')

      document.querySelector('.text_scroll').classList.remove('hide')
      document.querySelector('.losange-preload').classList.remove('hide')
      document.querySelector('.circle-load').classList.add('animated')

    }, 3000)

    setTimeout(() => {
      document.body.classList.remove('opening')
    }, 5000)

    setTimeout(() => {
      document.querySelector('.bottom-preload').classList.remove('show')
    }, 7000)


    setTimeout(() => {
      document.querySelector('.bottom-scroll').classList.add('show')
    }, 9000)



    //music

    if (!isTouch) {
      music = new Audio("sounds/zik.mp3");
      flip = new Audio("sounds/flip.mp3");

      music.loop = true
      music.volume = .05

      flip.volume = .15

      sound_ready = true;
      music.play()
    }

    //initVideo()

  },

  // Progress
  (itemUrl, itemsLoaded, itemsTotal) => {
    const progressRatio = itemsLoaded / itemsTotal
    //console.log(itemUrl, itemsLoaded, itemsTotal)

    if (itemsLoaded < itemsTotal) {
      document.querySelector('.number-top-preload').innerHTML = Math.round(progressRatio * 100)
    } else {
      document.querySelector('.number-top-preload').innerHTML = ""
    }
  }
)


const textureLoader = new THREE.TextureLoader(loadingManager);

const texture_empty = textureLoader.load('textures/cards/empty.png');
const texture_back = textureLoader.load('textures/back4.png');
const texture_front = textureLoader.load('textures/cards/queen.png');

const texture_shadow = textureLoader.load('textures/shadow.png');
const texture_alpha = textureLoader.load('textures/alpha.jpg');


/*
const videoDOM = document.querySelector("#videodom");
var videoTexture = new THREE.VideoTexture(videoDOM);
*/



var as = [
  { img: 'textures/cards/carreau/01.jpg', project: "missdior", video: "missdior", order: 0 },
  { img: 'textures/cards/trefle/01.jpg', project: "ritz_noel", video: "ritz_noel", order: 1 },
  { img: 'textures/cards/pique/01.jpg', project: "gucci", video: "gucci", order: 2 },
  { img: 'textures/cards/coeur/01.jpg', project: "jumbo", video: "jumbo", order: 3 },
]

var all = [
  { img: 'textures/alpha.jpg', project: "none" },
  { img: 'textures/alpha.jpg', project: "none" },
  { img: 'textures/alpha.jpg', project: "none" },
  { img: 'textures/alpha.jpg', project: "none" },
  { img: 'textures/alpha.jpg', project: "none" },
  { img: 'textures/alpha.jpg', project: "none" },
  { img: 'textures/alpha.jpg', project: "none" },
  { img: 'textures/alpha.jpg', project: "none" },
  { img: 'textures/alpha.jpg', project: "none" },

  { img: 'textures/cards/coeur/05.jpg', project: "dt51" },
  { img: 'textures/cards/coeur/02.jpg', project: "kokomi" },
  { img: 'textures/cards/coeur/04.jpg', project: "skp" },
  { img: 'textures/cards/trefle/valet.jpg', project: "rabanne", video: "rabanne", order: 10 },
  { img: 'textures/cards/carreau/dame.jpg', project: "rouge_dior", video: "rougedior", order: 26 },
  { img: 'textures/cards/coeur/valet.jpg', project: "minuty", video: "minuty", order: 18 },
  { img: 'textures/cards/carreau/valet.jpg', project: "nooz", video: "nooz", order: 14 },
  { img: 'textures/cards/pique/04.jpg', project: "guyhoquet" },
  { img: 'textures/alpha.jpg', project: "none" },

  { img: 'textures/cards/pique/10.jpg', project: "acp", video: "acp", order: 28 },
  { img: 'textures/cards/carreau/09.jpg', project: "marly", video: "marly", order: 23 },
  { img: 'textures/cards/pique/09.jpg', project: "shinsegae", video: "shinsegae", order: 8 },
  { img: 'textures/cards/pique/roi.jpg', project: "samaritaine", video: "samaritaine", order: 15 },
  { img: 'textures/cards/pique/dame.jpg', project: "guerlain", video: "guerlain", order: 4 },
  { img: 'textures/alpha.jpg', project: "none" },//As
  { img: 'textures/cards/coeur/dame.jpg', project: "mageline", video: "mageline", order: 6 },
  { img: 'textures/cards/pique/valet.jpg', project: "e8" },
  { img: 'textures/alpha.jpg', project: "none" },


  { img: 'textures/cards/pique/07.jpg', project: "pasta", video: "pasta", order: 22 },
  { img: 'textures/cards/coeur/06.jpg', project: "youmiam", video: "youmiam", order: 20 },
  { img: 'textures/cards/trefle/roi.jpg', project: "laduree", video: "laduree", order: 11 },
  { img: 'textures/alpha.jpg', project: "none" },//As
  { img: 'textures/cards/carreau/roi.jpg', project: "contact" },//milieu
  { img: 'textures/cards/coeur/09.jpg', project: "mac", video: "mac", order: 16 },
  { img: 'textures/alpha.jpg', project: "none" },//As
  { img: 'textures/cards/coeur/10.jpg', project: "myway", video: "armani", order: 24 },
  { img: 'textures/alpha.jpg', project: "none" },

  { img: 'textures/cards/coeur/08.jpg', project: "adp" },
  { img: 'textures/cards/pique/05.jpg', project: "crocs", video: "crocs", order: 17 },
  { img: 'textures/alpha.jpg', project: "none" },//As
  { img: 'textures/cards/carreau/04.jpg', project: "teritoria", video: "teritoria", order: 9 },
  { img: 'textures/cards/trefle/02.jpg', project: "fragonard", video: "fragonard", order: 5 },
  { img: 'textures/cards/trefle/10.jpg', project: "diorxmas", video: "diorxmas", order: 7 },
  { img: 'textures/cards/coeur/roi.jpg', project: "hennessy", video: "hennessy", order: 19 },
  { img: 'textures/cards/pique/06.jpg', project: "livy", video: "livy", order: 12 },
  { img: 'textures/alpha.jpg', project: "none" },

  { img: 'textures/cards/carreau/10.jpg', project: "jo", video: "jo", order: 27 },
  { img: 'textures/cards/trefle/04.jpg', project: "furlan" },
  { img: 'textures/cards/carreau/02.jpg', project: "boomboom", video: "boomboom", order: 21 },
  { img: 'textures/cards/trefle/06.jpg', project: "ritz_spa", video: "ritz_spa", order: 13 },
  { img: 'textures/cards/trefle/07.jpg', project: "snap", video: "snap", order: 29 },
  { img: 'textures/cards/carreau/05.jpg', project: "pierre_frey", video: "frey", order: 25 },
  { img: 'textures/alpha.jpg', project: "none" },
  { img: 'textures/alpha.jpg', project: "none" },
  { img: 'textures/alpha.jpg', project: "none" },

]

//as = as.sort((a, b) => 0.5 - Math.random());

/*

all = all.sort((a, b) => 0.5 - Math.random());
*/



const master_cards = []
const texture_cards = []

as.forEach((entry, index) => {

  let vdo = entry.video ? entry.video : "none"


  master_cards.push({ project: entry.project, video: vdo, order: entry.order })//entry.project
  texture_cards.push(textureLoader.load(entry.img))
  texture_cards[index].colorSpace = THREE.LinearSRGBColorSpace

})

all.forEach((entry, index) => {

  let vdo = entry.video ? entry.video : "none"
  master_cards.push({ project: entry.project, video: vdo, order: entry.order })//entry.project


  texture_cards.push(textureLoader.load(entry.img))
  texture_cards[index].colorSpace = THREE.LinearSRGBColorSpace
})


texture_empty.colorSpace = THREE.LinearSRGBColorSpace
texture_back.colorSpace = THREE.LinearSRGBColorSpace
texture_front.colorSpace = THREE.LinearSRGBColorSpace
texture_shadow.colorSpace = THREE.LinearSRGBColorSpace


document.querySelector('.videos-content').innerHTML = ""
var n = 0



/**
 * Objects
 */

var main = new THREE.Group()
var plateau = new THREE.Group()
var table = new THREE.Group()

scene.add(main)
scene.add(plateau)
scene.add(table)

var cards = []
var card_plateau = []

const card_geo = new THREE.BoxGeometry(.776, 1.199, 0);
const geometry = new THREE.PlaneGeometry(2.4, 2.4);
const material = new THREE.MeshBasicMaterial({ transparent: true, side: THREE.DoubleSide, map: texture_shadow });
const plane = new THREE.Mesh(geometry, material);
main.add(plane);
plane.rotation.x = Math.PI / 2
plane.position.y = -.09

plane.renderOrder = 2

//paquet

for (var i = 0; i < 54; i++) {

  cards.push(new THREE.Mesh(card_geo, [
    new THREE.MeshBasicMaterial({ color: 0x000000 }),
    new THREE.MeshBasicMaterial({ color: 0x000000 }),
    new THREE.MeshBasicMaterial({ color: 0x000000 }),
    new THREE.MeshBasicMaterial({ color: 0x000000 }),
    new THREE.MeshBasicMaterial({ map: texture_front, side: THREE.FrontSide, transparent: true, color: 0x777777, alphaMap: texture_alpha }),
    new THREE.MeshBasicMaterial({ map: texture_back, side: THREE.FrontSide, transparent: true })
  ]))

  main.add(cards[i]);

  cards[i].hover = hover_start
  cards[i].hover_progress = 0

  cards[i].renderOrder = 3
  cards[i].renderOrderMemo = 3

  cards[i].position.y = -.1 + i / 300
  cards[i].rotation.x = -Math.PI / 2
  cards[i].rotation.y = Math.PI
  cards[i].rotation.z = (Math.random() - .5) / 50

  cards[i].ry = Math.PI



  if (i == 53) {
    objectsToTest.push(cards[i])
    cards[i].order = 3
    cards[i].project = master_cards[3].project
    cards[i].video = master_cards[3].video
    cards[i].material[4].map = texture_cards[3]

  }

  if (i == 52) {
    objectsToTest.push(cards[i])
    cards[i].order = 2
    cards[i].project = master_cards[2].project
    cards[i].video = master_cards[2].video
    cards[i].material[4].map = texture_cards[2]
  }

  if (i == 51) {
    objectsToTest.push(cards[i])
    cards[i].order = 1
    cards[i].project = master_cards[1].project
    cards[i].video = master_cards[1].video
    cards[i].material[4].map = texture_cards[1]
  }

  if (i == 50) {
    objectsToTest.push(cards[i])
    cards[i].order = 0
    cards[i].project = master_cards[0].project
    cards[i].video = master_cards[0].video
    cards[i].material[4].map = texture_cards[0]
  }

  if (i == 49) {
    objectsToTest.push(cards[i])
  }

}

// plateau

const memoData = (card) => {


  card.mpx = card.position.x
  card.mpy = card.position.y
  card.mpz = card.position.z

  card.mrx = card.rotation.x
  card.mry = card.rotation.y
  card.mrz = card.rotation.z

}

const find_xy = (n) => {

  var iks = n % 9 - 4
  var igrek = Math.ceil((n + 1) / 9) - 4

  var coord = [{ x: 0, y: 0 }]
  coord.x = iks * .85
  coord.y = igrek * 1.27


  return coord;
}


var j = 0

for (var i = 54; i < 108; i++) {

  cards.push(new THREE.Mesh(card_geo, [
    new THREE.MeshBasicMaterial({ color: 0x000000 }),
    new THREE.MeshBasicMaterial({ color: 0x000000 }),
    new THREE.MeshBasicMaterial({ color: 0x000000 }),
    new THREE.MeshBasicMaterial({ color: 0x000000 }),
    new THREE.MeshBasicMaterial({ map: texture_cards[j + 4], side: THREE.FrontSide, transparent: true, color: 0x777777, alphaMap: texture_alpha }),
    new THREE.MeshBasicMaterial({ map: texture_back, side: THREE.FrontSide, transparent: true, color: 0x777777 })
  ]))

  card_plateau.push(new THREE.Mesh(card_geo, [
    new THREE.MeshBasicMaterial({ color: 0x000000 }),
    new THREE.MeshBasicMaterial({ color: 0x000000 }),
    new THREE.MeshBasicMaterial({ color: 0x000000 }),
    new THREE.MeshBasicMaterial({ color: 0x000000 }),
    new THREE.MeshBasicMaterial({ color: 0x000000 }),
    new THREE.MeshBasicMaterial({ map: texture_empty, side: THREE.FrontSide, transparent: true })
  ]))


  plateau.add(cards[i]);

  table.add(card_plateau[j]);

  cards[i].first = true
  cards[i].order = master_cards[i - 50].order
  cards[i].project = master_cards[i - 50].project
  cards[i].video = master_cards[i - 50].video

  cards[i].hover = hover_start
  cards[i].hover_progress = 0

  cards[i].renderOrder = 1
  cards[i].renderOrderMemo = 1

  cards[i].position.y = -.1
  cards[i].rotation.x = -Math.PI / 2
  cards[i].rotation.y = Math.PI

  cards[i].position.x = find_xy(j).x
  cards[i].position.z = find_xy(j).y

  cards[i].px = cards[i].position.x
  cards[i].py = cards[i].position.y
  cards[i].pz = cards[i].position.z

  cards[i].rx = cards[i].rotation.x
  cards[i].ry = cards[i].rotation.y
  cards[i].rz = cards[i].rotation.z

  memoData(cards[i])


  if (i != 77 && i != 84 && i != 87 && i != 92) {
    objectsToTest.push(cards[i])
  }

  card_plateau[j].position.x = cards[i].position.x
  card_plateau[j].position.y = cards[i].position.y - .001
  card_plateau[j].position.z = cards[i].position.z
  card_plateau[j].rotation.x = -Math.PI / 2
  card_plateau[j].rotation.y = Math.PI

  j++
}

cards[77].visible = false;
cards[84].visible = false;
cards[87].visible = false;
cards[92].visible = false;

var frontCard = new THREE.Mesh(card_geo, new THREE.MeshNormalMaterial)
main.add(frontCard)
frontCard.visible = false;


//init videos

objectsToTest.forEach((card, index) => {
  if (card.video && card.video != "none") {

    videos.push(card)
    let n = videos.length - 1

  }
  card.img = card.material[4].map
  card.state = "normal"
})

//console.log(videos)

videos = videos.sort((a, b) => {
  return a.order - b.order
})


videos.forEach(video => {
  //console.log(video.order, video.project)
})
//console.log(videos)



document.querySelector('.number-bottom').innerHTML = 4



const initVideo = () => {
  master_cards.forEach(card => {
    if (card.video != "none") {
      document.querySelector('.videos-content').innerHTML += `<video id="video${card.order}" data-src="${"videos/" + card.video + ".mp4"}" loop muted playsinline preload="none"></video>`

    }
  })

  htmlVideos = document.querySelectorAll('video')

}


/**
 * Mouse
 */


const mouse = new THREE.Vector2()


window.addEventListener('mousemove', (event) => {

  var iks = event.clientX
  var igrek = event.clientY

  mouse.x = iks / sizes.width * 2 - 1
  mouse.y = -(igrek / sizes.height) * 2 + 1

})


scrollBox.addEventListener('touchstart', (event) => {

  var iks = event.touches[0].clientX
  var igrek = event.touches[0].clientY

  mouse.x = iks / sizes.width * 2 - 1
  mouse.y = -(igrek / sizes.height) * 2 + 1

  touchStartY = event.touches[0].clientY

})







scrollBox.addEventListener('wheel', (event) => {

  //console.log(event.deltaY, okScroll, scrolling)


  if (scrolling >= 1.67 && event.deltaY > 0 && okScroll && first_card_closed) {

    okScroll = false
    nProject++
    nProject %= msg_end ? videos.length : 4
    target = videos[nProject]
    openFocus()


    setTimeout(() => {
      okScroll = true
    }, 1500)

  }


})




scrollBox.addEventListener('touchmove', (event) => {


  var swipeY = touchStartY - event.touches[0].clientY
  console.log(swipeY, okScroll, scrolling)


  if (scrolling >= 1.67 && swipeY > 40 && okScroll && first_card_closed) {

    okScroll = false
    nProject++
    nProject %= msg_end ? videos.length : 4
    target = videos[nProject]
    openFocus()


    setTimeout(() => {
      okScroll = true
    }, 1500)

  }

})





/**
 * Animate
 */


const animate_card = (card, speedMove) => {

  card.position.x = lerp(card.position.x, card.px, speedMove)
  card.position.y = lerp(card.position.y, card.py, speedMove)
  card.position.z = lerp(card.position.z, card.pz, speedMove)

  card.rotation.x = lerp(card.rotation.x, card.rx, speedMove)
  card.rotation.z = lerp(card.rotation.z, card.rz, speedMove)
  card.rotation.y = lerp(card.rotation.y, card.ry, speedMove)


}

var angle = 0

function animate() {
  requestAnimationFrame(animate);


  //raycaster

  objectsToTest.forEach((card, index) => {
    card.hover = hover_start
  })



  ///////////scrolling

  scrolling = lerp(scrolling, dest_scrolling, speed)
  //console.log(scrolling)


  //step 1

  cards[53].px = range(0, .3, 0, -.07, scrolling)
  cards[53].py = range(0, .3, -.1 + 53 / 300, 3.5, scrolling)
  cards[53].pz = range(0, .3, 0, -1.06, scrolling)
  cards[53].rx = range(0, .2, -Math.PI / 2, -.1, scrolling)
  cards[53].rz = range(0, .3, 0, -.1, scrolling)

  cards[52].py = range(.1, .4, -.1 + 52 / 300, 3.6, scrolling)
  cards[52].rx = range(.1, .3, -Math.PI / 2, -.1, scrolling)
  cards[52].pz = range(.1, .4, 0, -1.04, scrolling)
  cards[52].rz = range(.1, .4, 0, -.05, scrolling)
  cards[52].px = range(.1, .4, 0, -.03, scrolling)

  cards[51].py = range(.2, .5, -.1 + 51 / 300, 3.45, scrolling)
  cards[51].rx = range(.2, .4, -Math.PI / 2, -.1, scrolling)
  cards[51].pz = range(.2, .5, 0, -1.02, scrolling)
  cards[51].rz = range(.2, .5, 0, .05, scrolling)
  cards[51].px = range(.2, .5, 0, .06, scrolling)

  cards[50].py = range(.3, .6, -.1 + 50 / 300, 3.5, scrolling)
  cards[50].rx = range(.3, .5, -Math.PI / 2, -.1, scrolling)
  cards[50].pz = range(.3, .6, 0, -1, scrolling)
  cards[50].rz = range(.3, .5, 0, 0, scrolling)
  cards[50].px = range(.3, .6, 0, 0, scrolling)


  if (scrolling < .6) {

    cards[53].hover = 1
    cards[52].hover = range(0, .1, .6, 1, scrolling)
    cards[51].hover = range(.1, .2, .6, 1, scrolling)
    cards[50].hover = range(.2, .3, .6, 1, scrolling)
  }


  controls.target.y = range(.3, .6, 0, 3.8, scrolling)
  var evol_step1 = range(.3, .6, 1, 0, scrolling)

  angle += .002;

  let angleCam = Math.sin(angle) / 2 * evol_step1 + Math.PI / 4 * (1 - evol_step1) + Math.PI / 4
  camera.position.x = Math.cos(angleCam) * range(.3, .6, 12, 14, scrolling)
  camera.position.z = Math.sin(angleCam) * range(.3, .6, 12, 14, scrolling)
  camera.position.y = range(-1, 1, 7, 10, Math.sin((angle + seed) * 4) * evol_step1)


  if (scrolling >= .6 && scrolling < 1.4) {
    state = 1

    if (!isTouch) {
      cards[50].px = range(.6, .8, 0, -1.4, scrolling)
      cards[50].rz = range(.6, .8, 0, .05, scrolling)

      cards[51].px = range(.6, .8, 0.06, -.45, scrolling)
      cards[51].rz = range(.6, .8, 0.05, .02, scrolling)

      cards[52].px = range(.6, .8, -.03, .45, scrolling)
      cards[52].rz = range(.6, .8, -.05, .0, scrolling)

      cards[53].px = range(.6, .8, -.07, 1.4, scrolling)
      cards[53].rz = range(.6, .8, -.1, -.05, scrolling)

      cards[50].ry = range(.8, .9, Math.PI, 0, scrolling)
      cards[51].ry = range(.85, .95, Math.PI, 0, scrolling)
      cards[52].ry = range(.9, 1, Math.PI, 0, scrolling)
      cards[53].ry = range(.95, 1.05, Math.PI, 0, scrolling)

    } else {

      cards[50].py = range(.6, .8, 3.5, 4.5, scrolling)
      cards[50].rz = range(.6, .8, 0, .05, scrolling)
      cards[50].pz = range(.6, .8, -1, -.7, scrolling)

      cards[51].px = range(.6, .8, 0.06, -.45, scrolling)
      cards[51].rz = range(.6, .8, 0.05, .02, scrolling)

      cards[52].px = range(.6, .8, -.03, .45, scrolling)
      cards[52].rz = range(.6, .8, -.05, .0, scrolling)
      cards[52].pz = range(.6, .8, -1.04, -1.84, scrolling)
      cards[52].py = range(.6, .8, 3.45, 3.2, scrolling)

      cards[53].py = range(.6, .8, 3.5, 2.5, scrolling)
      cards[53].rz = range(.6, .8, -.1, -.05, scrolling)
      cards[53].pz = range(.6, .8, -1.06, -1.36, scrolling)


      cards[50].ry = range(.8, .9, Math.PI, 0, scrolling)
      cards[51].ry = range(.85, .95, Math.PI, 0, scrolling)
      cards[53].ry = range(.9, 1, Math.PI, 0, scrolling)
      cards[52].ry = range(.95, 1.05, Math.PI, 0, scrolling)

    }



    //turn


    cards[50].hover = range(.8, .9, 1, hover_start, scrolling)
    cards[51].hover = range(.85, .95, 1, hover_start, scrolling)
    cards[52].hover = range(.9, 1, 1, hover_start, scrolling)
    cards[53].hover = range(.95, 1.05, 1, hover_start, scrolling)

    var evol_step2 = range(.85, 1.15, 0, 1, scrolling)

    cards[53].rx = range(-1, 1, -.2, 0, Math.cos((angle * 8) + 3)) * evol_step2
    cards[52].rx = range(-1, 1, -.2, .0, Math.cos((angle * 8) + 2)) * evol_step2
    cards[51].rx = range(-1, 1, -.2, .0, Math.cos((angle * 8) + 1)) * evol_step2
    cards[50].rx = range(-1, 1, -.2, .0, Math.cos((angle * 8) + .0)) * evol_step2

    /*
        if (scrolling > .8 && touchAs && isTouch) {
          touchAs = false
          console.log('open first')
    
          setTimeout(() => {
            target = cards[50]
            openFocus()
          }, 1000)
    
        }
    */


  }

  if (scrolling >= 1.2) {

    cards[77].visible = false;
    cards[84].visible = false;
    cards[87].visible = false;
    cards[92].visible = false;

    var py53 = isTouch ? 2.5 : 3.5;
    var px53 = isTouch ? 0 : 1.4;
    var pz53 = isTouch ? -1.36 : -1.06;

    var pz52 = isTouch ? -1.84 : -1.04;
    var py52 = isTouch ? 3.45 : 3.6;

    var py50 = isTouch ? 4.5 : 3.5;
    var px50 = isTouch ? 0 : -1.4;
    var pz50 = isTouch ? -.7 : -1;

    cards[53].py = range(1.2, 1.7, py53, -.1, scrolling)
    cards[53].pz = range(1.2, 1.7, pz53, find_xy(87 - 54).y, scrolling)
    cards[53].px = range(1.2, 1.7, px53, find_xy(87 - 54).x, scrolling)
    cards[53].rx = range(1.2, 1.7, range(-1, 1, -.2, .1, Math.cos((angle * 8) + 3)), -Math.PI / 2, scrolling)
    cards[53].rz = range(1.2, 1.7, -.05, 0, scrolling)


    cards[52].py = range(1.2, 1.7, py52, -.1, scrolling)
    cards[52].pz = range(1.2, 1.7, pz52, find_xy(77 - 54).y, scrolling)
    cards[52].px = range(1.2, 1.7, .45, find_xy(77 - 54).x, scrolling)
    cards[52].rx = range(1.2, 1.7, range(-1, 1, -.2, .1, Math.cos((angle * 8) + 2)), -Math.PI / 2, scrolling)
    cards[52].rz = range(1.2, 1.7, 0, 0, scrolling)


    cards[51].py = range(1.2, 1.7, 3.45, -.1, scrolling)
    cards[51].pz = range(1.2, 1.7, -1.02, find_xy(84 - 54).y, scrolling)
    cards[51].px = range(1.2, 1.7, -.45, find_xy(84 - 54).x, scrolling)
    cards[51].rx = range(1.2, 1.7, range(-1, 1, -.2, .1, Math.cos((angle * 8) + 1)), -Math.PI / 2, scrolling)
    cards[51].rz = range(1.2, 1.7, .02, 0, scrolling)


    cards[50].py = range(1.2, 1.7, py50, -.1, scrolling)
    cards[50].pz = range(1.2, 1.7, pz50, find_xy(92 - 54).y, scrolling)
    cards[50].px = range(1.2, 1.7, px50, find_xy(92 - 54).x, scrolling)
    cards[50].rx = range(1.2, 1.7, range(-1, 1, -.2, .1, Math.cos((angle * 8))), -Math.PI / 2, scrolling)
    cards[50].rz = range(1.2, 1.7, .05, 0, scrolling)



    //

    controls.target.y = range(1.2, 1.7, 3.8, 0, scrolling)
    var evol_step3 = range(1.2, 1.7, 0, 1, scrolling)


    camera.position.y = range(-1, 1, 7, 10, Math.sin((angle + seed) * 2) * evol_step3)

    angle -= .001;

    angleCam = Math.sin(angle) / 2 * evol_step3 + Math.PI / 4 * (1 - evol_step3) + Math.PI / 4
    camera.position.x = Math.cos(angleCam) * range(1.2, 1.7, 14, 12, scrolling)
    camera.position.z = Math.sin(angleCam) * range(1.2, 1.7, 14, 12, scrolling)



    if (scrolling > 1.68) {

      if (!msg_end) {
        msg_end = true
        target = cards[85]
        openFocus()
        document.querySelector('.number-bottom').innerHTML = videos.length
      } else {
        document.querySelector('.bottom-scroll').classList.remove('show')
        document.querySelector('.bottom-project').classList.add('show')
      }

    }

    //last

    if (state == 1) {
      state = 2


      for (var i = 0; i < 50; i++) {
        cards[i].visible = false;
      }

      cards[49].position.z = 20

      plateau.visible = true
      table.visible = false

      plane.visible = false;

      cards[53].hover = hover_start
      cards[52].hover = hover_start
      cards[51].hover = hover_start
      cards[50].hover = hover_start
    }


  } else {

    state = 0
    //first
    for (var i = 0; i < 50; i++) {
      cards[i].visible = true;
    }
    cards[49].position.z = 0

    plateau.visible = false
    table.visible = true


    plane.visible = true;



  }

  //scoll indication
  roundText.style.transform = `rotate(${scrolling * 100}deg)`


  //focus

  if (focusMode) {

    if (state == 2)

      focusTarget.material[4].color.set(white)

    focusTarget.renderOrder = 10

    var test = new THREE.Vector3()

    camera.getWorldDirection(test)

    //console.log(test)

    var size = isTouch ? 7 : 12;

    focusTarget.py = camera.position.y + test.y * size
    focusTarget.px = camera.position.x + test.x * size
    focusTarget.pz = camera.position.z + test.z * size

    frontCard.position.set(focusTarget.px, focusTarget.py, focusTarget.pz)
    frontCard.lookAt(camera.position)


    focusTarget.ry = frontCard.rotation.y
    focusTarget.rx = frontCard.rotation.x
    focusTarget.rz = frontCard.rotation.z


    objectsToTest.forEach((card, index) => {

      if (card != focusTarget) {
        card.hover = .3
      } else {
        card.hover = 1
      }
    })

  }

  for (var c = 50; c < 54; c++) {
    if (scrollMode) {
      animate_card(cards[c], .1)
      memoData(cards[c])
    } else {
      animate_card(cards[c], .05)
    }
  }


  for (var i = 54; i < 108; i++) {
    animate_card(cards[i], .05)
  }


  //hover

  raycaster.setFromCamera(mouse, camera)
  const intersects = raycaster.intersectObjects(objectsToTest)

  if (intersects.length > 0 && scrolling >= .6 && !nohover && intersects[0].object.project != "none") {

    target = intersects[0].object
    //console.log(target.project)
    target.hover = 1
    document.body.classList.add('pointer')
  } else {
    document.body.classList.remove('pointer')

  }

  objectsToTest.forEach((card, index) => {

    var teint = new THREE.Color()



    if (card.state == "fade_out_video" || card.state == "fade_out_img") {
      card.hover_progress = lerp(card.hover_progress, 0, .1)


      if (card.hover_progress < .01 && card.state == "fade_out_video") {
        launchVideo(card)
        card.state = "fadein"
      }

      if (card.hover_progress < .05 && card.state == "fade_out_img") {
        backImg(card)
        card.state = "normal"
      }

    } else if (card.state == "fadein") {
      card.hover_progress = lerp(card.hover_progress, 1, .01)
    } else {
      card.hover_progress = lerp(card.hover_progress, card.hover, .04)
    }


    teint.lerpColors(black, white, card.hover_progress)
    card.material[4].color.set(teint)
    card.material[5].color.set(teint)


  })


  controls.update();
  renderer.render(scene, camera);
}





scrollBox.addEventListener('scroll', (e) => {


  if (!document.body.classList.contains('scrollStop')) {
    dest_scrolling = scrollBox.scrollTop / sizes.height
    scrollMode = true
  }

  if ((isTouch && focusMode && deltaScroll - scrollBox.scrollTop > 0 && scrolling > 1.67) || (!isTouch && focusMode) || (isTouch && focusMode && scrolling < 1.67)) {
    closeFocus()
  }

  if (dest_scrolling < .8) {
    document.querySelector('.bottom-scroll').classList.add('show')
    document.querySelector('.bottom-project').classList.remove('show')
  }

  deltaScroll = scrollBox.scrollTop

})



const openFocus = () => {

  if (!video_ready) {
    initVideo()
    video_ready = true
  }

  backFocus()

  lastProject = target.project

  if (target.project == "contact" && !first_card_closed) {
    //window.location.href = "mailto:a.colado@malherbe.paris"
    setTimeout(() => {
      first_card_closed = true
    }, 2500);

  }

  if (!document.body.classList.contains('scrollStop')) {
    memo_scroll = scrollBox.scrollTop
  }

  focusReady = true
  focusTarget = target

  memoCard.px = target.mpx
  memoCard.py = target.mpy
  memoCard.pz = target.mpz

  memoCard.rx = target.mrx
  memoCard.ry = 0
  memoCard.rz = target.mrz



  document.body.classList.add('scrollStop')
  focusMode = true
  document.body.classList.remove('pointer')

  scrollMode = false



  if (focusTarget.video != "none") {

    findProject()
    startVideo(focusTarget)

  }

  document.querySelector('.bottom-scroll').classList.remove('show')
  document.querySelector('.bottom-project').classList.add('show')

  if (focusTarget.project != "contact" && focusTarget.video != "none") {
    document.querySelector('.bottom-project').classList.remove('start')
    document.querySelector('.bottom-project').classList.add('loading-video')
  } else {
    document.querySelector('.bottom-project').classList.add('start')
    document.querySelector('.bottom-project').classList.remove('loading-video')
  }

  if (focusTarget.first) {

    if (!isTouch) {
      flip.play()
    }

    focusTarget.first = false
  }


  gtag("event", "page_view", {
    page_title: focusTarget.project,
    page_location: window.location.href
  });

}

const startVideo = (trgt) => {


  trgt.state = "buffering"
  document.querySelector('#video' + trgt.order).setAttribute('src', document.querySelector('#video' + trgt.order).dataset.src)
  document.querySelector('#video' + trgt.order).load()

  setTimeout(() => {

    if (trgt.state == "buffering") {
      trgt.state = "fade_out_video"
    }


  }, 1800)
}

const backFocus = () => {
  focusTarget.px = memoCard.px
  focusTarget.py = memoCard.py
  focusTarget.pz = memoCard.pz
  focusTarget.rx = memoCard.rx
  focusTarget.ry = memoCard.ry
  focusTarget.rz = memoCard.rz

  focusTarget.renderOrder = focusTarget.renderOrderMemo

  //video.pause()
  //video.classList.remove('show')

  htmlVideos.forEach(video => {
    video.pause()
  })

  objectsToTest.forEach((card, index) => {

    if (card.state == "fadein") {
      card.state = "fade_out_img"
    }

    if (card.state == "fade_out_video" || card.state == "buffering") {
      card.state = "normal"
    }


  })

}

const closeFocus = () => {

  backFocus()
  document.body.classList.remove('scrollStop')
  scrollBox.scrollTop = memo_scroll

  focusMode = false

  objectsToTest.forEach((card, index) => {
    card.hover = hover_start
  })



  setTimeout(() => {
    lastProject = ""
  }, 500)

  if (!isTouch) {
    document.querySelector('.bottom-scroll').classList.add('show')
    document.querySelector('.bottom-project').classList.remove('show')
  }


  document.querySelector('.bottom-project').classList.add('start')
  document.querySelector('.bottom-project').classList.remove('loading-video')

}

scrollBox.addEventListener('click', () => {

  if (document.body.classList.contains('pointer') || (isTouch && !nohover && scrolling > .8)) {

    if (target.project == lastProject && okScroll) {
      closeFocus()

    } else {
      openFocus()
    }
  }



})


document.querySelector('.logo-top').addEventListener('click', () => {
  document.querySelector('.scroll-box').scrollTop = 0
})



document.querySelectorAll('.bottom-project,.logo-top').forEach(cta => {

  cta.addEventListener('mouseover', () => {
    nohover = true
  })

  cta.addEventListener('mouseout', () => {
    nohover = false
  })
})




const findProject = () => {
  nProject = videos.indexOf(focusTarget)
}


document.querySelector('.cta-next').addEventListener('click', () => {

  nohover = true

  closeFocus()

  if (!msg_end && nProject == 3) {
    closeFocus()
    scrollBox.scrollTo(0, scrollBox.scrollHeight);
  } else {
    nProject++
    nProject %= msg_end ? videos.length : 4
    target = videos[nProject]
    openFocus()
  }

  console.log('next')
})



document.querySelector('.cta-prev').addEventListener('click', () => {
  nohover = true

  closeFocus()

  if (msg_end) {
    nProject += videos.length - 1
    nProject %= videos.length
  } else {
    nProject += 3
    nProject %= 4
  }

  console.log(nProject)
  target = videos[nProject]
  openFocus()

  console.log('next')
})


document.querySelector('.scroll-content').addEventListener('touchstart', (event) => {
  console.log('ok')
  nohover = false
})



const launchVideo = (card) => {

  //htmlVideos[nProject].play()

  document.querySelector('#video' + card.order).play()

  var vdoT = new THREE.VideoTexture(document.querySelector('#video' + card.order));
  vdoT.needsUpdate = true

  card.material[4].map = vdoT
  card.hover = 0

  document.querySelector('.number-top').innerHTML = (nProject + 1)
  document.querySelector('.bottom-project').classList.remove('loading-video')
}

const backImg = (card) => {
  card.material[4].map = card.img
  card.hover = hover_start
}


document.body.addEventListener('click', () => {
  if (sound_ready && !isTouch) {
    music.play()
  }
})



document.querySelector('.interface').classList.add('show')

